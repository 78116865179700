import { Box, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import pdf from '../../assets/adatkez.pdf';
import logo from '../../assets/jsn_logo_prof_horz_color_rgb.jpg';

export default function Footer(): JSX.Element {
  return (
    <Flex
      backgroundColor="dpcblue.600"
      color="white"
      alignItems="center"
      justifyContent="space-between"
      py="1rem"
      px={['3rem', null, '5rem', '8rem']}
      flexDirection={['column', null, 'row', null]}
    >
      <Box textDecoration="underline" flexGrow={1}>
        <a
          href={pdf}
          download="pation_Adatkezelési_tájékoztató.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Adatkezelési tájékoztató
        </a>
      </Box>
      <Flex flexDirection="column" flexGrow={2} alignItems="center">
        <Image
          src={logo}
          maxHeight={['70px', null, '100px', null]}
          height="auto"
          width="auto"
          my={['1rem', null, '0', null]}
        />
        <Box textAlign="center">
          Az alkalmazás megvalósítását a Janssen támogatta.
        </Box>
      </Flex>
      <Box flexGrow={1} textAlign="end">
        © 2021 - DPC
      </Box>
    </Flex>
  );
}
