import { Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Fonts } from '../../assets/fonts/Fonts';
import Footer from './Footer';
import Header from './Header';

export interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps): JSX.Element {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          name
          description
        }
      }
    }
  `);

  return (
    <React.StrictMode>
      <Fonts />
      <Flex minH="100vh" flexDir="column">
        <Helmet
          titleTemplate={`%s - ${data.site.siteMetadata.name}`}
          defaultTitle={data.site.siteMetadata.name}
        >
          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />
        </Helmet>
        <header>
          <Header />
        </header>
        <Flex
          flexGrow={1}
          margin="auto"
          mt="3rem"
          mb="1rem"
          width="100%"
          justifyContent="center"
        >
          {children}
        </Flex>

        <footer>
          <Footer />
        </footer>
      </Flex>
    </React.StrictMode>
  );
}
