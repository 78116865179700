import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';

import DPCLogo from '../../assets/dpclogo-transparent-320x102-v3.png';

export default function Header(): JSX.Element {
  const location = useLocation();

  const linkTexts = [
    { path: '/', text: 'Oltópont' },
    { path: '/hny', text: 'Nyilatkozat kitöltése' },
  ];

  return (
    <Flex
      p={1}
      px={['3rem', null, '5rem', '8rem']}
      py="1rem"
      borderBottomWidth="0.5rem"
      borderBottomColor="dpcblue.500"
    >
      <Flex display={['flex', null, 'none']} width="100%">
        <Flex mr="1rem">
          <Menu autoSelect={false}>
            <MenuButton fontWeight="bold">
              <HamburgerIcon w={6} h={6} />
            </MenuButton>
            <MenuList>
              {linkTexts.map((l) => (
                <MenuItem
                  key={l.path}
                  bg={location.pathname === l.path ? 'dpcgreen.500' : 'inherit'}
                  _hover={{
                    bg:
                      location.pathname === l.path
                        ? 'dpcgreen.500'
                        : 'dpcgreen.faded',
                  }}
                  as={Link}
                  to={l.path}
                >
                  {l.text}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        <Flex flexGrow={1} justifyContent="center">
          <Image src={DPCLogo} alt="logo" width="10rem" />
        </Flex>
      </Flex>

      <Flex display={['none', null, 'flex']} width="100%">
        <Box as={Link} to="/" width="15rem" flexShrink={0}>
          <Image src={DPCLogo} alt="logo" />
        </Box>
        <Flex flexGrow={1} justifyContent="left" alignItems="center" px="3rem">
          {linkTexts.map((l) => (
            <Box
              key={l.path}
              as={Link}
              to={l.path}
              textAlign="center"
              mx="1rem"
              color={location.pathname === l.path ? 'dpcgreen.500' : 'inherit'}
              style={
                location.pathname === l.path
                  ? {
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                    }
                  : {}
              }
              _hover={{
                color:
                  location.pathname === l.path
                    ? 'dpcgreen.500'
                    : 'dpcgreen.faded',
              }}
            >
              {l.text}
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
